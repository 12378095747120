import Vue from 'vue'
import App from './App.vue'

import { Toast } from 'vant'
Vue.use(Toast)

// if(document.documentElement.clientWidth < 1200){
  require('./utils/flexible.js');
// }
require('./assets/common.css');

new Vue({
  render: h => h(App),
}).$mount('#app')
