/* eslint-disable */
!(function (e, t) {
    function i() {
        let t = n.getBoundingClientRect().width;
        t / m > 750 && (t = 75 * m);
        const i = t / 10;
        n.style.fontSize = `${i}px`, s.rem = e.rem = i;
    }
    let a; const r = e.document;
    var n = r.documentElement;
    let o = r.querySelector('meta[name="viewport"]');
    const l = r.querySelector('meta[name="flexible"]');
    var m = 0;
    let d = 0;
    var s = t.flexible || (t.flexible = {});
    if (o) {
        console.warn('将根据已有的meta标签来设置缩放比例');
        const p = o.getAttribute('content').match(/initial\-scale=([\d\.]+)/);
        p && (d = parseFloat(p[1]), m = parseInt(1 / d));
    } else if (l) {
        const c = l.getAttribute('content');
        if (c) {
            const u = c.match(/initial\-dpr=([\d\.]+)/);
            const f = c.match(/maximum\-dpr=([\d\.]+)/);
            u && (m = parseFloat(u[1]), d = parseFloat((1 / m).toFixed(2))), f && (m = parseFloat(f[1]), d = parseFloat((1 / m).toFixed(2)));
        }
    }
    if (!m && !d) {
        const v = e.navigator.appVersion.match(/android/gi);
        const h = e.chrome;
        const x = e.navigator.appVersion.match(/iphone|ipad/gi);
        const b = e.devicePixelRatio;
        const w = (v || x) && h;
        m = x || w ? b >= 3 && (!m || m >= 3) ? 3 : b >= 2 && (!m || m >= 2) ? 2 : 1 : 1, d = 1 / m;
    }
    if (n.setAttribute('data-dpr', m), !o) {
        if (o = r.createElement('meta'), o.setAttribute('name', 'viewport'), o.setAttribute('content', `initial-scale=${d}, maximum-scale=${d}, minimum-scale=${d}, user-scalable=no`), n.firstElementChild) n.firstElementChild.appendChild(o);
        else {
            const g = r.createElement('div');
            g.appendChild(o), r.write(g.innerHTML);
        }
    }
    e.addEventListener('resize', () => { clearTimeout(a), a = setTimeout(i, 300); }, !1), e.addEventListener('pageshow', (e) => { e.persisted && (clearTimeout(a), a = setTimeout(i, 300)); }, !1), r.readyState === 'complete' ? r.body.style.fontSize = `${12 * m}px` : r.addEventListener('DOMContentLoaded', (e) => { r.body.style.fontSize = `${12 * m}px`; }, !1), i(), s.dpr = e.dpr = m, s.refreshRem = i, s.rem2px = function (e) {
        let t = parseFloat(e) * this.rem;
        return typeof e === 'string' && e.match(/rem$/) && (t += 'px'), t;
    }, s.px2rem = function (e) {
        let t = parseFloat(e) / this.rem;
        return typeof e === 'string' && e.match(/px$/) && (t += 'rem'), t;
    };
}(window, window.lib || (window.lib = {})));
