<template>
  <div class="default">
    <div class="con-back">
      <img src="../assets/img/back.webp" alt="" @click="close">
    </div>
    <div class="con-center con-logo">
      <img src="../assets/img/logo.webp" alt="">
    </div>
    <ul class="con-center con-btns">
      <li>
        <img :class="{'active': activeBtn === 'ios' && isBtnActive }" src="../assets/img/btn_ios.webp" alt="" @click="clickDownloadIos">
      </li>
      <li>
        <img :class="{'active': activeBtn === 'apkd' && isBtnActive }" src="../assets/img/btn_apk.webp" alt="" @click="clickDownloadApkd">
      </li>
      <!-- <li>
        <img :class="{'active': this.activeBtn === 'apkgp'}" src="../assets/img/btn_gp.webp" alt="" @click="clickDownloadApkgp">
      </li> -->
    </ul>
    <div class="con-center con-dlg">
      <img src="../assets/img/dlg.webp" alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      "android_gp": "",
      "android_default": "",
      "ios": "",
      "activeBtn": ""
    }
  },
  mounted() {
    this.initDownloadApp()
  },
  methods: {
    clickDownloadApkd() {
      if (this.isBtnActive)  return
      this.isBtnActive = true
      this.activeBtn = 'apkd'
      setTimeout(() => {
        this.activeBtn = ''
        this.isBtnActive = false
      }, 500)

      if (this.android_default) {
        this.downloadAppByUrl(this.android_default)
      }
      else {
        this.$toast({
          message: "no android download url.",
        });
        return
      }
    },
    clickDownloadIos() {
      if (this.isBtnActive)  return
      this.isBtnActive = true
      this.activeBtn = 'ios'
      setTimeout(() => {
        this.activeBtn = ''
        this.isBtnActive = false
      }, 500)

      if (this.ios) {
        this.downloadAppByUrl(this.ios)
      }
      else {
        this.$toast({
          message: "Coming soon!",
        });
        return
      }
    },
    downloadAppByUrl(_url){
      let iframe = document.getElementsByTagName('iframe')
      if (iframe) {
        iframe = document.createElement('iframe')
        iframe.setAttribute("style", "display:none")
        iframe.src = _url
        document.body.appendChild(iframe)
      }
    },
    async initDownloadApp() {
      let r = Math.floor(10000 * Math.random())
      let resp = await axios.get('https://hotdown.top3dgame.com/craftleague-dis-apk/downloadUrl.json?r=' + r)
      if (resp.data) {
        Object.assign(this, resp.data)
      }

      let _url = ''
      if (navigator.userAgent.match(/Android/) && this.android_default) {
        _url = this.android_default
      }
      else if ((navigator.userAgent.match(/iPhone/) || navigator.userAgent.match(/iPad/)) && this.ios) {
        _url = this.ios
      }
      if(_url) {
        this.downloadAppByUrl(_url)
      }
    },
    close(){
      window.close()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.default {
  background-color: #04aaf4;
  width: 100%;
  min-height: 100%;
}
.con-back {
  position: absolute;
  left: 16px;
  top: 16px;
}
.con-back img {
  width: 43px;
  height: auto;
}
.con-center {
  text-align: center;
  padding: 20px 0;
}
.con-logo {
  padding-top: 0;
}
.con-logo img {
  width: 307px;
  height: auto;
}
.con-btns li {
  padding: 5px 0;
}
.con-btns img {
  width: 168px;
}
.con-dlg img {
  width: 307px;
}

.con-btns img.active {
  animation: myclick .6s; /*Safari and Chrome*/
}

@keyframes myclick
{
	from { transform: scale(1); }
  30% { transform: scale(1.1); }
	to { transform: scale(1); }
}
</style>
