<template>
  <div id="app">
    <Default />
  </div>
</template>

<script>
import Default from './components/default.vue';

export default {
  name: 'App',
  components: {
    Default
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
li,ul {
  list-style: none;
}
html,body,#app {
  width: 100%;
  height: 100%;
}
</style>
